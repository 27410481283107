import React, { useMemo, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { Doughnut } from "react-chartjs-2";
import sortImg from "assets/img/sort.png";
import SubmitLoader from "components/SubmitLoader";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Collapse,
  Label,
  Input,
  Row,
  Spinner,
  Col,
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { sortList, drawCanvas, notify } from "components/functions";
import { useDispatch, useSelector } from "react-redux";

import { getAllProductCount } from "store/AddGroupAction";
// AllProductCountDataSuccess
function CreateMedicalProposal() {
  const notificationAlert = React.useRef();
  const [alert, setAlert] = React.useState(null);
  const [loadingData, setLoadingData] = React.useState(true);
  const getOptionUrl = process.env.REACT_APP_API_URL + "/get-options/";
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [countrytOptions, setcountrytOptions] = useState([]);
  const [person, setperson] = useState();
  const [location, setlocation] = useState();
  const [requirement, setrequirement] = useState([
    { value: "hospitalization", label: "Hospitalization" },
  ]);
  const [nationality, setnationality] = useState();
  const [Premiumtype, setPremiumtype] = useState("premium");
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [PersonInfoState, setPersonInfoState] = useState("has-success");
  const [personNoState, setpersonNoState] = useState("");
  const [locationState, setlocationState] = useState("");
  const [nationalityState, setnationalityState] = useState("");
  const [productResult, setproductResult] = useState({ data: {} });
  const [openedCollapses, setOpenedCollapses] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [productCount, setProductCount] = React.useState("");
  const [resultCount, setResultCount] = React.useState("");
  const [selectedPlans, setSelectedPlans] = useState(0);
  let history = useHistory();
  const params = useParams();
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const dispatch = useDispatch();
  const { AllProductCountDataSuccess } = useSelector(
    (state) => state.getAllProductCountDataRe
  );
  console.log("nationality", nationality);
  // const name = localStorage.getItem('name');
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
    } else {
      openedCollapses.push(collapse);
      setOpenedCollapses([...openedCollapses, collapse]);
    }
  };
  let proposal_products = useMemo(() => [], []);
  let personOptions = [];
  let additionlInfo = [];
  let policyHolderAgeOption = [];
  let additionalPersonAge = [];
  for (let index = 1; index <= 8; index++) {
    personOptions.push({ value: index, label: index });
  }
  for (let index = 18; index <= 90; index++) {
    policyHolderAgeOption.push({ value: index, label: index });
  }
  for (let index = 0; index <= 90; index++) {
    additionalPersonAge.push({ value: index, label: index });
  }
  const requirementOptions = [
    {
      value: "hospitalization",
      label: "Hospitalization",
      isFixed: true,
      isDisabled: true,
    },
    { value: "outpatient", label: "Outpatient" },
    { value: "dental", label: "Dental" },
    { value: "maternity", label: "Maternity" },
  ];
  const genderOptions = [
    { value: "", label: "Gender", isDisabled: true },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  useEffect(() => {
    async function getOptions() {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(getOptionUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          //console.log(data);
          if (data.success) {
            setcountrytOptions(data.countries);
            setLoadingData(false);
          } else {
            setLoadingData(false);
          }
        });
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getOptions();
    }
  }, []);

  useEffect(() => {
    if (AllProductCountDataSuccess?.data?.success == true) {
      setProductCount(AllProductCountDataSuccess?.data?.dataCount);
    }
  }, [AllProductCountDataSuccess]);

  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };
  const handleProposalSelection = (e) => {
    const checked = e.target.checked;
    if (checked) {
      if(selectedPlans < 7){
        proposal_products.push(
          e.target.value
        );
        setSelectedPlans(selectedPlans+1);
      }
      else{
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="info"
            confirmBtnText="OK"
            btnSize=""
          >
            You are unable to select more than 7 policies per proposal.
          </ReactBSAlert>
        );
        e.target.checked = false;
      }
    } else {
      var index = proposal_products.indexOf(
        e.target.value
      );
      if (index > -1) {
        proposal_products.splice(index, 1);
        setSelectedPlans(selectedPlans-1);
      }
    }
  }
  const [formValues, setFormValues] = useState([
    { gender: "", age: "", relation_status: "self" },
  ]);
  const switchStep = (step) => {
    if (step == setStep2) {
      if (personNoState !== "has-success") {
        setpersonNoState("has-danger");
      }
      if (locationState !== "has-success") {
        setlocationState("has-danger");
      }

      if (personNoState === "has-success" && locationState === "has-success") {
        //additionlInfo[0] = { gender: "", age: "", relation_status: "" };
        for (let index = 1; index <= person.value; index++) {
          //additionlInfo[index] = { gender: "", age: "", relation_status: "" };
          additionlInfo.push({ gender: "", age: "", relation_status: "" });
        }
        setStep1(false);
        step(true);
        // setadditonalpersonform(additionlInfo);
        setFormValues(additionlInfo);
      }
    }
    //addFormFields();
    if (step == setStep1) {
      setStep2(false);
      setStep1(true);
    }
    if (step == setStep3) {
      getProduct(0);
      getProductLimit();
    }
  };
  const filterArray = (req, value) => {
    for (let index = 0; index <= req.length - 1; index++) {
      //console.log(req[index]['value']);
      if (req[index]["value"] == value) {
        return true;
        break;
      }
    }
  };
  const CreateProposal = () => {
    const createMedicalProposalUrl =
      process.env.REACT_APP_API_URL + "/create-medical-proposal";
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    var data = {
      hospitalization: filterArray(requirement, "hospitalization")
        ? "yes"
        : "no",
      outpatient: filterArray(requirement, "outpatient") ? "yes" : "no",
      dental: filterArray(requirement, "dental") ? "yes" : "no",
      maternity: filterArray(requirement, "maternity") ? "yes" : "no",
      location: location.value,
      no_of_person: person.value,
      persons: formValues,
      products: proposal_products,
      client_id: params.client_id,
      Premiumtype: Premiumtype,
    };

    if (proposal_products.length > 0) {
      setShowLoader(true);
      fetch(createMedicalProposalUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          if (!data.success) {
          } else {
            notify("Proposal Created Successfully.", 2, notificationAlert);
            setShowLoader(false);
            setTimeout(function () {
              history.push("/admin/opportunities/" + params.client_id);
            }, 2000);
          }
        });
    } else {
      notify("Please Select a plan to continue.", 3, notificationAlert);
    }
  };

  const getProductLimit = async () => {
    var data = {
      hospitalization: filterArray(requirement, "hospitalization")
        ? "yes"
        : "no",
      outpatient: filterArray(requirement, "outpatient") ? "yes" : "no",
      dental: filterArray(requirement, "dental") ? "yes" : "no",
      maternity: filterArray(requirement, "maternity") ? "yes" : "no",
      location: location.value,
      no_of_person: person.value,
      persons: formValues,
      nationality: nationality?.value
    };
    await dispatch(getAllProductCount(data, loggedUser.token));
  };

  const getProduct = async (productlimit) => {
    // console.log(requirement);
    let flag = "no-error";
    for (var i = 0; i < formValues.length; i++) {
      if (
        formValues[i].gender == "" ||
        formValues[i].age == "" ||
        formValues[i].relation_status == ""
      ) {
        flag = "error";
      }
    }
    if (flag === "error") {
      setPersonInfoState("has-danger");
    } else {
      setPersonInfoState("has-success");
    }
    if (nationalityState !== "has-success") {
      setpersonNoState("has-danger");
    }

    if (flag === "no-error" && nationalityState === "has-success") {
      setShowLoader(true);
      if (productlimit === 0) {
        // if (!productCount) {
        //   await getProductLimit();
        // }

        var getMedicalUrl =
          process.env.REACT_APP_API_URL + "/get-medical-products/ASC/premium/1";
      } else {
        // console.log("productlimit", productlimit);
        // if (!productCount) {
        //   getProductLimit();
        // }
        var getMedicalUrl =
          process.env.REACT_APP_API_URL +
          `/get-medical-products/ASC/premium/1/${productlimit}`;
      }
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      var data = {
        hospitalization: filterArray(requirement, "hospitalization")
          ? "yes"
          : "no",
        outpatient: filterArray(requirement, "outpatient") ? "yes" : "no",
        dental: filterArray(requirement, "dental") ? "yes" : "no",
        maternity: filterArray(requirement, "maternity") ? "yes" : "no",
        location: location?.value,
        no_of_person: person?.value,
        persons: formValues,
        nationality: nationality?.value
      };
      console.log("productlimit12345", data);

      fetch(getMedicalUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("productlimit", data);
          if (!data.success) {
          } else {
            setproductResult(data);
            setResultCount(data.limit);
            // setProductCount(data.dataCount);
            setShowLoader(false);
          }
        });

      setStep3(true);
      setStep2(false);
    }
  };
  console.log("productCount", productCount);
  console.log("resultCount", resultCount);
  let handleChange = (i, e, type, name = "") => {
    let newFormValues = [...formValues];

    if (type === "select") {
      newFormValues[i][name] = e.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    if (i === 0) {
      newFormValues[i]["relation_status"] = "self";
    }
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { gender: "", age: "", relation_status: "" },
    ]);
  };

  const LoadMore = () => {
    if (productResult) {
      var productLimit = productResult.limit;
      getProduct(productLimit);
    }
  };
  return (
    <div className="content">
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      {alert}
      <Row>
        {loadingData ? (
          <p>Loading Please wait...</p>
        ) : (
          <Col md="12">
            <Card>
              <CardHeader>
                <h5></h5>
              </CardHeader>
              <CardBody>
                <Row className={step1 ? "" : "d-none"}>
                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${personNoState}`}>
                      <label>Select Number of Insured Person</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        onChange={(e) => {
                          setperson(e);
                          if (e.value === "") {
                            setpersonNoState("has-danger");
                          } else {
                            setpersonNoState("has-success");
                          }
                        }}
                        options={personOptions}
                        placeholder="Select Number of Insured Persons"
                      />
                      {personNoState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label ${locationState}`}>
                      <label>Select Location</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        onChange={(e) => {
                          setlocation(e);
                          if (e.value === "") {
                            setlocationState("has-danger");
                          } else {
                            setlocationState("has-success");
                          }
                        }}
                        options={countrytOptions}
                        placeholder="Select Location"
                      />
                      {locationState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col className="ml-auto mr-auto" md="4">
                    <FormGroup className={`has-label`}>
                      <label>Select Your Requirements:</label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Select Your Requirements"
                        hideSelectedOptions={false}
                        isMulti
                        defaultValue={[requirementOptions[0]]}
                        isClearable={false}
                        options={requirementOptions}
                        components={{ MultiValueRemove }}
                        onChange={(e) => {
                          setrequirement(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="12">
                    <Button
                      color="primary"
                      onClick={(e) => {
                        switchStep(setStep2);
                      }}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>

                <Row className={step2 ? "" : "d-none"}>
                  <Col md="12">
                    {PersonInfoState === "has-danger" ? (
                      <label className="error text-danger">
                        All Fields are required.
                      </label>
                    ) : null}
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="2">
                        <FormGroup className={`has-label`}>
                          <label>Policy Holder Detail</label>
                        </FormGroup>
                      </Col>
                      <Col className="ml-auto mr-auto" md="2">
                        <FormGroup className={`has-label`}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            onChange={(e) =>
                              handleChange(0, e, "select", "age")
                            }
                            options={policyHolderAgeOption}
                            placeholder="Select Age"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="ml-auto mr-auto" md="4">
                        <FormGroup className={`has-label`}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            onChange={(e) =>
                              handleChange(0, e, "select", "gender")
                            }
                            options={genderOptions}
                            placeholder="Select Gender"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="ml-auto mr-auto" md="4">
                        <FormGroup className={`has-label ${nationalityState}`}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            onChange={(e) => {
                              setnationality(e);
                              if (e.value === "") {
                                setnationalityState("has-danger");
                              } else {
                                setnationalityState("has-success");
                              }
                            }}
                            options={countrytOptions}
                            placeholder="Select Nationality"
                          />
                          {nationalityState === "has-danger" ||
                          nationalityState === "" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    {formValues.map((element, index) =>
                      index + 1 < formValues.length ? (
                        <Row key={index}>
                          <Col className="ml-auto mr-auto text-center" md="2">
                            <FormGroup className={`has-label`}>
                              <label>Person {index + 1}</label>
                            </FormGroup>
                          </Col>
                          <Col className="ml-auto mr-auto" md="2">
                            <FormGroup className={`has-label`}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                onChange={(e) => {
                                  handleChange(index + 1, e, "select", "age");
                                  setPersonInfoState("has-success");
                                }}
                                options={additionalPersonAge}
                                placeholder="Select Age"
                              />
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="4">
                            <FormGroup className={`has-label`}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                onChange={(e) => {
                                  handleChange(
                                    index + 1,
                                    e,
                                    "select",
                                    "gender"
                                  );
                                  setPersonInfoState("has-success");
                                }}
                                options={genderOptions}
                                placeholder="Select Gender"
                              />
                            </FormGroup>
                          </Col>

                          <Col className="ml-auto mr-auto" md="4">
                            <FormGroup className={`has-label`}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                onChange={(e) => {
                                  handleChange(
                                    index + 1,
                                    e,
                                    "select",
                                    "relation_status"
                                  );
                                  setPersonInfoState("has-success");
                                }}
                                options={[
                                  { value: "Partner", label: "Partner" },
                                  { value: "child", label: "Child" },
                                ]}
                                placeholder="Select R-Status"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null
                    )}

                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="12">
                        <Button
                          color="primary"
                          onClick={(e) => {
                            switchStep(setStep1);
                          }}
                        >
                          Previous
                        </Button>
                        <Button
                          color="primary"
                          onClick={(e) => {
                            switchStep(setStep3);
                          }}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className={step3 ? "" : "d-none"}>
                  <Col md="12">
                    <ul className="list-group" id="id01">
                      <li className="list-group-item bg-dark text-light">
                        <Row>
                          <Col
                            md="1"
                            className="pl-1 ml-auto mr-auto text-center font-weight-bold"
                          >
                          Insurer
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Hospitalization
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Outpatient
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Dental
                          </Col>
                          <Col
                            md="1"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Maternity
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                            onClick={(e) => {
                              sortList("rating");
                            }}
                          >
                            Customer Rating <img src={sortImg}></img>
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            <p
                              onClick={(e) => {
                                sortList(Premiumtype);
                              }}
                            >
                              Premium <img src={sortImg}></img>
                            </p>
                            <FormGroup className={`has-label`}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                onChange={(e) => setPremiumtype(e.value)}
                                options={[
                                  { value: "premium", label: "Monthly" },
                                  {
                                    value: "annual_premium",
                                    label: "Annually",
                                  },
                                ]}
                                defaultValue={{
                                  value: "Monthly",
                                  label: "Monthly",
                                }}
                                placeholder="Premium Type"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </li>

                      {productResult.data.length > 0 ? (
                        productResult.data.map((element, index) => (
                          <li
                            key={index}
                            className="list-group-item bg-light mt-4"
                          >
                            <Row key={index}>
                              <Col
                                md="1"
                                className="ml-auto mr-auto text-center"
                              >
                                <img
                                  src={element.product.insurer_logo}
                                  width="100"
                                  height="100"
                                ></img>
                              </Col>
                              <Col
                                md="2"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.hospitalization_radio ===
                                "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.hospitalization_radio ===
                                  "text" ? (
                                  element.product.hospitalization_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="2"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.outpatient_radio === "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.outpatient_radio ===
                                  "text" ? (
                                  element.product.outpatient_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="2"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.dental_radio === "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.dental_radio === "text" ? (
                                  element.product.dental_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="1"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.maternity_radio === "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.maternity_radio ===
                                  "text" ? (
                                  element.product.maternity_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="2"
                                data-val={element.product.customer_rating}
                                className="ml-auto mr-auto text-center ratings"
                              >
                                <Doughnut
                                  data={drawCanvas(
                                    element.product.customer_rating,
                                    "data"
                                  )}
                                  options={drawCanvas(
                                    element.product.customer_rating,
                                    "options"
                                  )}
                                  className="ct-chart ct-perfect-fourth"
                                  height={20}
                                  width={20}
                                />
                              </Col>
                              <Col
                                md="2"
                                data-val={element.product.premium}
                                data-val1={element.product.annual_premium}
                                className="ml-auto mr-auto text-center premium"
                              >
                                {Premiumtype === "premium"
                                  ? `${element.product.premium} USD / Month`
                                  : element.product.annual_premium === 0
                                  ? "N/A"
                                  : `${element.product.annual_premium} USD / Year`}

                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      value={element.product.product_id}
                                      onChange={handleProposalSelection}
                                    />
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12" className="text-center">
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      className="text-primary font-weight-bold"
                                      aria-expanded={openedCollapses.includes(
                                        "collapseOne"
                                      )}
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() =>
                                        collapsesToggle("collapse" + index)
                                      }
                                    >
                                      See Full Benefits
                                      <br />
                                      <i className="nc-icon nc-minimal-down" />
                                    </a>
                                  </CardHeader>
                                  <Collapse
                                    role="tabpanel"
                                    isOpen={openedCollapses.includes(
                                      "collapse" + index
                                    )}
                                  >
                                    <CardBody className="text-left">
                                      <Row>
                                        <Col md="4">
                                          <Row>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Provider
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.insurer_title}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Annual Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.annual_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Hospital Cover
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.hospital_cover}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Physiotherapy
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.physiotherapy}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Dental Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.dental_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Maternity Wait Period
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {
                                                element.product
                                                  .maternity_wait_period
                                              }
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="4">
                                          <Row>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Plan name
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.plan_name}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Deductible/Excess
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {
                                                element.product
                                                  .deductible_excess
                                              }
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Oncology/Cancer
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.oncology_cancer}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Vaccinations
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.vaccinations}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Dental Wait Period
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {
                                                element.product
                                                  .dental_wait_period
                                              }
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Evacuation
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.evacuation}
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="4">
                                          <Row>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Product
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.product_type}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Network
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.network}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Outpatient Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.outpatient_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Annual Physical
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.annual_physical}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Maternity Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.maternity_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Area of Cover
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.area_of_cover}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                              </Col>
                            </Row>
                          </li>
                        ))
                      ) : (
                        <li className="list-group-item">No Data Found !</li>
                      )}
                    </ul>
                  </Col>

                  <Col md="12" style={{ textAlign: "right" }}>
                    <p><b>Showing: {resultCount} entries</b></p>
                    <p><b>Selected: {selectedPlans}</b></p>
                  </Col>
                  <Col md="12" className="text-center">
                    <Button
                      color="primary"
                      onClick={(e) => {
                        CreateProposal();
                      }}
                    >
                      Create Proposal
                    </Button>
                  </Col>

                  {productCount == resultCount ? null : (
                    <Col md="12" className="text-center">
                      {" "}
                      {!productCount ? (
                        <Spinner type="grow" color="primary" children={false} />
                      ) : (
                        <Button
                          color="primary"
                          onClick={(e) => {
                            LoadMore();
                          }}
                        >
                          Load More
                        </Button>
                      )}
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
export default CreateMedicalProposal;
